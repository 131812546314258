import React from 'react';
import './Images.css';


class Images extends React.Component {

    render() {
        return (
            <div className="images">
                <h1>Imágenes</h1>
            </div>
        );
    }
} export default Images;
