import React from 'react';
import './Home.css';
// import { faHeadphonesAlt } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home() {

    return (
        <div className="home">
            <h1>Victor Santal</h1>
            {/* <FontAwesomeIcon icon={faHeadphonesAlt} /> */}
        </div>
    );
}

export default Home;