import React from 'react';
import './Videos.css';


class Videos extends React.Component {

    render() {
        return (
            <div className="videos">
                <h1>Vídeos</h1>
            </div>
        );
    }
} export default Videos;
