import React from 'react';
import './Music.css';

function Music() {

    return (
        <div className="music">
            <h1>Música</h1>
        </div>
    );
}

export default Music;